<template>
  <div :class="!isApp ? 'appTop' : ''">
    <van-list
      v-model:loading="loading"
      :finished="finished"
      :finished-text="list.length ? '-到底啦-' : ''"
      offset="200"
      class="recordList"
      :class="!isApp ? 'appTop' : ''"
      @load="onLoad"
    >
      <div class="recordItem" v-for="item in list" :key="item.id">
        <div class="name">
          <div>{{ item.user_name }}</div>
          <!-- <div>ID：{{ item.receive_user_id }}</div> -->
        </div>

        <div class="success">
          <div>
            <div v-if="item.id_verify == 0">已注册</div>
            <div v-if="item.id_verify == 1 && item.is_buy == 0">已实名，未购买</div>
            <div v-if="item.id_verify == 1 && item.is_buy == 1">已购买</div>
          </div>

          <div>
            {{ getTime(item.updated_time) }}
          </div>
        </div>
      </div>
    </van-list>

    <div v-if="isLoading" class="dataNullBox">
      <div>
        <img src="@/imgs/loading/loading.gif" style="width: 2rem; height: 2rem" alt="" srcset="" />
      </div>
      <div class="text">数据加载中……</div>
    </div>
    <div v-else-if="isLoadError" class="dataNullBox">
      <div>
        <img src="@/imgs/comm/dataNull.png" alt="" srcset="" />
      </div>
      <div class="text" @click="getInviteLog">数据加载失败，请检查网络设置后刷新页面</div>
    </div>
    <div v-else-if="!list.length" class="dataNullBox">
      <div>
        <img src="@/imgs/comm/dataNull.png" alt="" srcset="" />
      </div>
      <div class="text">暂无邀请记录</div>
    </div>

    <PageLoading :show="pageLoading" />
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
import { nftUtils } from '@/utils';
import { getuserinvitationlogshoudan } from '@/service/invite';
import PageLoading from '@/components/loading/PageLoading.vue';
import moment from 'moment';

export default {
  components: {
    PageLoading
  },
  setup() {
    const state = reactive({
      isLogin: nftUtils.isLogin(),
      isApp: nftUtils.isInApp(),
      list: [],
      finished: false,
      page: 1,
      loading: true,
      isLoading: false,
      pageLoading: false
    });

    onMounted(async () => {});

    // 加载更多
    const onLoad = () => {
      if (!state.finished && state.list.length) {
        console.log(state.page);
        state.page = state.page + 1;
      }
      getInviteLog();
    };

    // 获取邀请记录
    const getInviteLog = () => {
      if (!state.isLogin) {
        state.loading = false;
        return;
      }
      if (state.page === 1) {
        state.isLoadError = false;
        state.isLoading = true;
      }
      getuserinvitationlogshoudan({
        page: state.page,
        page_size: 10,
        // act_id: 1
        act_id: 58
      })
        .then(res => {
          console.log(res);
          if (res.status === 0) {
            state.pageLoading = false;
            state.list = state.list.concat(res.data || []);

            state.loading = false;
            if (!res.data || !res.data.length) {
              state.finished = true;
            }
            if (res.data && res.data.length < 10) {
              state.finished = true;
            }
            if (state.page === 1) {
              state.isLoadError = false;
              state.isLoading = false;
            }
          }
        })
        .catch(err => {
          if (state.page === 1) {
            state.loading = false;
            state.finished = true;
            state.isLoading = false;
            state.isLoadError = true;
          }
        });
    };

    // 获取格式时间
    const getTime = (time, format = 'YYYY-MM-DD HH:mm:ss') => {
      return moment(time).format(format);
    };

    return {
      ...toRefs(state),
      onLoad,
      getTime
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixin.scss';
.appTop {
  padding-top: 70px !important;
}
.recordList {
  padding: 0 20px;
  padding-top: 54px;
  .recordItem {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    margin-top: 20px;

    .name {
      :first-child {
        font-size: 14px;
      }

      :last-child {
        margin-top: 6px;
        color: rgba(255, 255, 255, 1);
      }
    }

    .success {
      text-align: right;

      :first-child {
        font-weight: 600;
      }

      :last-child {
        margin-top: 6px;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
.dataNullBox {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  img {
    width: 150px;
    height: 110px;
  }

  .text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
